import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Spinner } from 'react-bootstrap';
import { indexCommentRequest } from '../../../requests/comments';
import CommentForm from './CommentForm';
import Question from './Question';
import Answer from './Answer';

const QuestionAndAnswers = ({ solicitudeId }) => {
  const [comments, setComents] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const isClient = location.pathname.includes('client');
  const _isMounted = useRef(true);

  const {
    notifications: { unread: unreadNotificationLength }
  } = useSelector(state => state.utils);

  const fetchComments = () => {
    setIsFetching(true);
    indexCommentRequest({
      dispatch,
      params: { solicitude_id: solicitudeId, comment_id: 'true' },
      successCallback: response => {
        if (_isMounted.current) {
          setComents(
            camelCaseRecursive(
              response.data.data.map(i => {
                i.user_name = i.user_name.split(' ')[0];
                return i;
              })
            )
          );
          setIsFetching(false);
        }
      }
    });
  };

  useEffect(() => {
    if (unreadNotificationLength > 0) {
      fetchComments();
    }
  }, [unreadNotificationLength]);

  useEffect(() => {
    _isMounted.current = true;
    fetchComments();
    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <>
      {isFetching ? (
        <div
          className="d-flex justify-content-center align-items-center w-100 h-100"
          style={{ minHeight: '500px' }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <div className="question-and-answers bottom-animation">
            <h5 className="mb-3">Preguntas y Respuestas</h5>

            {!isClient && (
              <CommentForm
                fetchComments={fetchComments}
                solicitudeId={solicitudeId}
                buttonText="Preguntar a cliente"
              />
            )}

            <h6 className="mb-2 mt-2 mt-sm-n2">Últimas Realizadas</h6>

            {comments.length === 0 && (
              <p>Todavía no existen preguntas y respuestas</p>
            )}

            {comments.map(comment => {
              return (
                <div key={comment.id}>
                  <Question
                    key={comment.id}
                    comment={comment}
                    isCLient={isClient}
                    answersLenght={comment.comments.length}
                    fetchComments={fetchComments}
                    solicitudeId={solicitudeId}
                  />
                  {comment.comments.map(answer => {
                    answer.userName = answer.userName.split(' ')[0];
                    return <Answer key={answer.id} comment={answer} />;
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default QuestionAndAnswers;
