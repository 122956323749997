import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Form, withFormik, Field, getIn } from 'formik';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { UploadImage, IcoMoon } from '../../../components';
import { BasicProfileFields } from '../../../components/Profile';

const ProfileClientForm = ({
  modelName,
  errors,
  touched,
  setFieldValue,
  user,
  onRequest,
  values,
  submitCount
}) => {
  const {
    clientAttributes: { frontIdentity, backIdentity }
  } = values.user;
  useEffect(() => {
    console.log(errors);
    if (errors.user) {
      const errorKeys = Object.keys(errors.user);

      if (!!errorKeys.length && submitCount > 0) {
        console.log(`name["${errorKeys[0]}"]`);
        const firstElement = document.querySelector(
          `[name*="${errorKeys[0]}"]`
        );
        if (firstElement !== document.activeElement) {
          firstElement.focus();
        }
      }
    }
  }, [submitCount]);
  return (
    <Form>
      <BasicProfileFields
        modelName={modelName}
        errors={errors}
        touched={touched}
        user={user}
        setFieldValue={setFieldValue}
      />
      <Row className="mt-4 ml-xl-n4">
        <Col md={6}>
          <Field name={`${modelName}[frontIdentity]`}>
            {({ field }) => {
              return (
                <UploadImage
                  {...field}
                  abbr
                  numberId="03"
                  label="Cédula de identidad (frontal)"
                  iconComponent={
                    <div className="circle-image">
                      <IcoMoon icon="image1" size={20} />
                    </div>
                  }
                  classNameImg="img-license-input"
                  classNameEmpty="empty-img-input"
                  accept="image/jpg, image/png, image/jpeg"
                  setFieldValue={setFieldValue}
                  imageUrl={getIn(frontIdentity, 'fileUrlMinified')}
                  fileName={getIn(frontIdentity, 'filename')}
                  onChange={images => setFieldValue(field.name, images)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              );
            }}
          </Field>
        </Col>
        <Col md={6}>
          <Field name={`${modelName}[backIdentity]`}>
            {({ field }) => {
              return (
                <UploadImage
                  {...field}
                  abbr
                  numberId="04"
                  label="Cédula de identidad (reverso)"
                  iconComponent={
                    <div className="circle-image">
                      <IcoMoon icon="image1" size={20} />
                    </div>
                  }
                  classNameImg="img-license-input"
                  classNameEmpty="empty-img-input"
                  accept="image/jpg, image/png, image/jpeg"
                  setFieldValue={setFieldValue}
                  imageUrl={getIn(backIdentity, 'fileUrlMinified')}
                  fileName={getIn(backIdentity, 'filename')}
                  onChange={images => setFieldValue(field.name, images)}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              );
            }}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col xs={8} sm={6} md={4} className="ml-auto mt-4">
          <Button
            variant="success"
            type="submit"
            className="mt-4 mt-md-0"
            disabled={onRequest}
            block
          >
            {onRequest && (
              <Spinner
                as="span"
                animation="border"
                className="mr-2"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Guardar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { user } = props;

  const emptyFrontIdentity =
    Object.entries(user?.clientAttributes?.frontIdentityImg || {}).length === 0;
  const emptyBackIdentity =
    Object.entries(user?.clientAttributes?.backIdentityImg || {}).length === 0;

  return {
    user: {
      avatar: {},
      email: user.email,
      clientAttributes: {
        id: user.clientAttributes?.id || '',
        name: user.clientAttributes?.name || '',
        lastName: user.clientAttributes?.lastName || '',
        description: user.clientAttributes?.description || '',
        phone: user.clientAttributes?.phone || '',
        frontIdentity: emptyFrontIdentity
          ? ''
          : user.clientAttributes?.frontIdentityImg,
        backIdentity: emptyBackIdentity
          ? ''
          : user.clientAttributes?.backIdentityImg
      }
    }
  };
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    clientAttributes: Yup.object().shape({
      name: Yup.string().required('Debes ingresar tu nombre'),
      lastName: Yup.string().required('Debes ingresar tu apellido'),
      phone: Yup.string()
        .required('Debes ingresar tu teléfono')
        .test('len', 'Teléfono incorrecto', val => val?.trim().length === 14),
      frontIdentity: Yup.mixed().required('Debes adjuntar una imagen'),
      backIdentity: Yup.mixed().required('Debes adjuntar una imagen')
    })
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProfileClientForm);
