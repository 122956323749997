import React from 'react';
import { Field, getIn } from 'formik';
import { Row, Col } from 'react-bootstrap';
import {
  FormikInput,
  FormikCheckBox,
  NestedAttributes,
  InputImage,
  FormikSelect
} from '../index';
import { vehiclesTypes } from '../../screens/Driver/Profile/formOptions';

const VehiclesAttributes = ({
  vehiclesAttributes,
  errors,
  touched,
  setFieldValue,
  modelName,
  deleteVehiclesPhotos,
  setFieldTouched
}) => {
  const addItem = () => {
    const mapResults = vehiclesAttributes.map((body, index) => {
      if (body._destroy) return undefined;

      return (
        <div
          key={`vehicle-item-${index.toString()}`}
          className="container-fields-vehicle-items opacity-animation"
        >
          {index > 0 && <hr className="my-5" />}
          <h6 className="mb-3">Vehículo N˚{index + 1}</h6>
          <Row>
            <Col md={4}>
              <Field
                name={`${modelName}[vehiclesAttributes][${index}][car_make]`}
              >
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Marca"
                    placeholder="Ingresa la marca de tu vehículo"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field
                name={`${modelName}[vehiclesAttributes][${index}][car_model]`}
              >
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Modelo"
                    placeholder="Ingresa el modelo de tu vehículo"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field
                name={`${modelName}[vehiclesAttributes][${index}][car_year]`}
              >
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="number"
                    label="Año de tu vehículo"
                    placeholder="Ingresa el año de tu vehículo"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Field
                name={`${modelName}[vehiclesAttributes][${index}][license_plate]`}
              >
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Patente"
                    placeholder="Ingresa la patente de tu vehículo"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col md={4}>
              <Field
                name={`${modelName}[vehiclesAttributes][${index}][vehicle_type]`}
              >
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    label="Tipo de vehículo"
                    options={vehiclesTypes}
                    defaultValue={vehiclesAttributes[index]?.vehicle_type}
                    onChange={data =>
                      setFieldValue(field.name, data ? data.value : '')
                    }
                    setFieldTouched={() => setFieldTouched(field.name)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <h6 className="my-4">Medidas</h6>
          <Row>
            <Col md={12}>
              <Field
                name={`${modelName}[vehiclesAttributes][${index}][no_medidas]`}
              >
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    abbr="true"
                    field={field}
                    className="mt-3"
                    label="No quiero ingresar medidas"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          {!body.no_medidas && (
            <Row>
              <Col md={4}>
                <Field
                  name={`${modelName}[vehiclesAttributes][${index}][height]`}
                >
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      inputType="number"
                      label="Altura (cm)"
                      placeholder="0 cm"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={4}>
                <Field
                  name={`${modelName}[vehiclesAttributes][${index}][length]`}
                >
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      inputType="number"
                      label="Largo (cm)"
                      placeholder="0 cm"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={4}>
                <Field
                  name={`${modelName}[vehiclesAttributes][${index}][width]`}
                >
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      inputType="number"
                      label="Ancho (cm)"
                      placeholder="0 cm"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          )}

          <Row className="d-flex justify-content-end">
            <Col md={8}>
              <h6 className="my-5 mb-4">Agrega imágenes de tu vehículo</h6>
              <Field
                name={`${modelName}[vehiclesAttributes][${index}][vehicle_photos]`}
              >
                {({ field, meta }) => (
                  <InputImage
                    images={meta.value}
                    imageKey="file_url_minified"
                    destroyImage={imageId => {
                      setFieldValue(
                        `${modelName}[deleteVehiclesPhotos]`,
                        [...deleteVehiclesPhotos, imageId].flat()
                      );
                    }}
                    getImages={images => {
                      const nameImages = `${modelName}[vehiclesAttributes][${index}][photos]`;
                      setFieldValue(
                        nameImages,
                        images.map(i => i.file).filter(i => i)
                      );
                      setFieldValue(
                        field.name,
                        images.map(img => ({
                          file: img.file,
                          file_url_minified: img.file_url_minified,
                          filename: img?.filename || img.file?.name,
                          id: img?.id
                        }))
                      );
                    }}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </div>
      );
    });

    return (
      <>
        <NestedAttributes
          mapInputs={mapResults}
          arrayValues={vehiclesAttributes}
          setFieldValue={setFieldValue}
          valuePath={`${modelName}[vehiclesAttributes]`}
          newAttributes={{
            car_make: '',
            car_model: '',
            car_year: '',
            license_plate: '',
            vehicle_type: '',
            height: '',
            length: '',
            width: '',
            photos: [],
            vehicle_photos: []
          }}
          addTitle="Añadir otro vehículo"
          classNameDeleteBtn="mt-20"
          deleteBtnTooltipText="Eliminar vehículo"
        />
      </>
    );
  };

  return addItem();
};

export default VehiclesAttributes;
