import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { Row, Col, Button } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import { FormikDatePicker, FormikInput } from '../../../components/Utils/Input';
import UrgencyButtons from '../../Client/Solicitude/UrgencyButtons';
import { createTripRequest, updateTripRequest } from '../../../requests/trips';
import AddressFields from '../../../components/Address/AddressFields';

let formRequest;

const NewTripForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  setShowModal,
  setShowAlert,
  trip
}) => {
  const dispatch = useDispatch();
  const [urgencyBtnSelected, setUrgencyBtnSelected] = useState({
    left: false,
    right: false
  });
  const [urgencyTypeLeft, setUrgencyTypeLeft] = useState('');
  const [urgencyTypeRight, setUrgencyTypeRight] = useState('');
  const [tripState, setTripState] = useState({
    isActive: true,
    isClosed: false
  });
  const isType = type => type.split('-')[0];

  const changeTripState = state => {
    if (state === 'active') {
      setFieldValue('trip[active]', true);
      setTripState({
        isActive: true,
        isClosed: false
      });
    } else {
      setFieldValue('trip[active]', false);
      setTripState({
        isActive: false,
        isClosed: true
      });
    }
  };

  const currentStartDate = () => {
    if (trip) {
      setFieldValue('trip[id]', trip.id);
      setFieldValue('trip[active]', trip.active);
      setFieldValue('trip[startDate]', moment(trip.startDate).toDate());
      setFieldValue('trip[endDate]', moment(trip.endDate).toDate());
      setFieldValue(
        'trip[pickupAddressAttributes][id]',
        trip.pickupAddressAttributes.id
      );
      setFieldValue(
        'trip[pickupAddressAttributes][streetName]',
        trip.pickupAddressAttributes.streetName
      );
      setFieldValue(
        'trip[pickupAddressAttributes][communeId]',
        trip.pickupAddressAttributes.communeId
      );
      setFieldValue(
        'trip[pickupAddressAttributes][latitude]',
        trip.pickupAddressAttributes.latitude
      );
      setFieldValue(
        'trip[pickupAddressAttributes][longitude]',
        trip.pickupAddressAttributes.longitude
      );
      setFieldValue('trip[pickupAddressAttributes][isValidAddress]', true);
      setFieldValue(
        'trip[deliveryAddressAttributes][streetName]',
        trip.deliveryAddressAttributes.streetName
      );
      setFieldValue(
        'trip[deliveryAddressAttributes][communeId]',
        trip.deliveryAddressAttributes.communeId
      );
      setFieldValue(
        'trip[deliveryAddressAttributes][id]',
        trip.deliveryAddressAttributes.id
      );
      setFieldValue(
        'trip[deliveryAddressAttributes][latitude]',
        trip.deliveryAddressAttributes.latitude
      );
      setFieldValue(
        'trip[deliveryAddressAttributes][longitude]',
        trip.deliveryAddressAttributes.longitude
      );
      setFieldValue('trip[deliveryAddressAttributes][isValidAddress]', true);
      setFieldValue('trip[originDetour]', trip.originDetour);
      setFieldValue('trip[isValidDestiny]', true);
      setFieldValue('trip[destinyDetour]', trip.destinyDetour);
      setFieldValue('trip[pickupOption]', trip.pickupOption);
      setFieldValue(
        'trip[pickupStartDate]',
        moment(trip.pickupStartDate).toDate()
      );
      setFieldValue('trip[pickupEndDate]', moment(trip.pickupEndDate).toDate());

      setFieldValue('trip[deliveryOption]', trip.deliveryOption);
      setFieldValue(
        'trip[deliveryStartDate]',
        moment(trip.deliveryStartDate).toDate()
      );
      setFieldValue(
        'trip[deliveryEndDate]',
        moment(trip.deliveryEndDate).toDate()
      );

      if (trip.active === true) {
        changeTripState('active');
      } else {
        changeTripState('closed');
      }

      setUrgencyTypeLeft(`left-${trip.pickupOption}`);
      setUrgencyTypeRight(`right-${trip.deliveryOption}`);
    } else {
      setFieldValue('trip[startDate]', moment().toDate());
      setFieldValue('trip[endDate]', moment().toDate());
    }
  };
  useEffect(currentStartDate, []);

  const {
    startDate,
    endDate,
    pickupStartDate,
    pickupEndDate,
    deliveryStartDate,
    deliveryEndDate
  } = values.trip;

  const pickupOptionError = getIn(errors, 'trip[pickupOption]');
  const deliveryOptionError = getIn(errors, 'trip[deliveryOption]');
  const pickupStartDateTouched = getIn(touched, 'trip[pickupStartDate]');
  const pickupEndDateTouched = getIn(touched, 'trip[pickupEndDate]');
  const deliveryEndDateTouched = getIn(touched, 'trip[deliveryEndDate]');
  const deliveryStartDateTouched = getIn(
    touched,
    'solicitude[deliveryStartDate]'
  );

  formRequest = dataValues => {
    const myParams = snakeCaseKeys(dataValues);

    if (trip) {
      const tripId = trip.id;
      updateTripRequest(tripId, {
        dispatch,
        params: myParams,
        formData: true,
        successCallback: () => {
          setShowModal(false);
          setShowAlert(true);
        }
      });
    } else {
      createTripRequest({
        dispatch,
        params: myParams,
        formData: true,
        successCallback: () => {
          setShowModal(false);
          setShowAlert(true);
        },
        callback: () => setShowModal(false)
      });
    }
  };

  const fieldStartDate = 'trip[startDate]';
  const fieldEndDate = 'trip[endDate]';

  const renderUrgencyInputLeft = () => {
    const fieldPickupStartDate = 'trip[pickupStartDate]';
    const fieldPickupEndDate = 'trip[pickupEndDate]';

    if (urgencyTypeLeft === 'left-between_range') {
      return (
        <Row className="mt-fields mb-n1">
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha de inicio"
              selected={pickupStartDate || new Date()}
              placeholderText="Seleccionar inicio"
              dateFormat="dd-MM-yyyy"
              onChange={date => {
                setFieldValue(fieldPickupStartDate, date);
              }}
              error={getIn(errors, fieldPickupStartDate)}
              touched={getIn(touched, fieldPickupStartDate)}
            />
          </Col>
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha de término"
              selected={pickupEndDate}
              placeholderText="Seleccionar término"
              dateFormat="dd-MM-yyyy"
              onChange={date => {
                setFieldValue(fieldPickupEndDate, date);
              }}
              error={getIn(errors, fieldPickupEndDate)}
              touched={getIn(touched, fieldPickupEndDate)}
            />
          </Col>
        </Row>
      );
    }
    if (urgencyTypeLeft === 'left-on_date') {
      return (
        <Row className="mt-fields mb-n1">
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha"
              selected={pickupStartDate || new Date()}
              dateFormat="dd-MM-yyyy"
              placeholderText="Seleccionar un día"
              onChange={date => {
                setFieldValue(fieldPickupStartDate, date);
                setFieldValue(fieldPickupEndDate, date);
              }}
              error={getIn(errors, fieldPickupStartDate)}
              touched={getIn(touched, fieldPickupEndDate)}
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderUrgencyInputRight = () => {
    const fieldDeliveryStartDate = 'trip[deliveryStartDate]';
    const fieldDeliveryEndDate = 'trip[deliveryEndDate]';

    if (urgencyTypeRight === 'right-between_range') {
      return (
        <Row className="mt-fields mb-n1">
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha de inicio"
              selected={deliveryStartDate || new Date()}
              placeholderText="Seleccionar inicio"
              dateFormat="dd-MM-yyyy"
              onChange={date => {
                setFieldValue(fieldDeliveryStartDate, date);
              }}
              error={getIn(errors, fieldDeliveryStartDate)}
              touched={getIn(touched, fieldDeliveryStartDate)}
            />
          </Col>
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha de término"
              selected={deliveryEndDate}
              placeholderText="Seleccionar término"
              dateFormat="dd-MM-yyyy"
              onChange={date => {
                setFieldValue(fieldDeliveryEndDate, date);
              }}
              error={getIn(errors, fieldDeliveryEndDate)}
              touched={getIn(touched, fieldDeliveryEndDate)}
            />
          </Col>
        </Row>
      );
    }
    if (urgencyTypeRight === 'right-on_date') {
      return (
        <Row className="mt-fields mb-n1">
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha"
              selected={deliveryStartDate || new Date()}
              dateFormat="dd-MM-yyyy"
              placeholderText="Seleccionar un día"
              onChange={date => {
                setFieldValue(fieldDeliveryStartDate, date);
                setFieldValue(fieldDeliveryEndDate, date);
              }}
              error={getIn(errors, fieldDeliveryStartDate)}
              touched={getIn(touched, fieldDeliveryStartDate)}
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  return (
    <div className="d-flex justify-content-start h-100 w-100 p-3">
      <Form className="w-100">
        <Row className="mt-fields mb-n1">
          <Col sm={12} md={6} lg={3} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Comienza el"
              selected={startDate || new Date()}
              placeholderText="Seleccionar inicio"
              minDate={new Date()}
              dateFormat="dd-MM-yyyy"
              onChange={date => {
                setFieldValue(fieldStartDate, date);
              }}
              error={getIn(errors, fieldStartDate)}
              touched={getIn(touched, fieldStartDate)}
            />
          </Col>
          <Col sm={12} md={6} lg={3} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Finaliza el"
              selected={endDate || new Date()}
              minDate={startDate}
              placeholderText="Seleccionar inicio"
              dateFormat="dd-MM-yyyy"
              onChange={date => {
                setFieldValue(fieldEndDate, date);
              }}
              error={getIn(errors, fieldEndDate)}
              touched={getIn(touched, fieldEndDate)}
            />
          </Col>
          {trip && (
            <Col
              sm={12}
              md={12}
              lg={6}
              className="d-flex flex-column justify-content-start align-items-start pt-1 pl-4"
            >
              <p style={{ fontSize: '1.05rem', fontWeight: '600' }}>
                Estado del viaje
              </p>
              <div className="mt-3 d-flex justify-content-between align-items-between form-input-radio">
                <div
                  className=" d-flex justify-content-start"
                  aria-hidden="true"
                  onClick={() => changeTripState('active')}
                >
                  <input
                    type="radio"
                    checked={tripState.isActive}
                    style={{
                      width: '20px',
                      height: '20px',
                      accentColor: 'red'
                    }}
                  />
                  <p className="ml-2" style={{ fontSize: '14px' }}>
                    Recibiendo oportunidades
                  </p>
                </div>
                <div
                  aria-hidden="true"
                  className="ml-3  d-flex justify-content-start"
                  onClick={() => changeTripState('closed')}
                >
                  <input
                    type="radio"
                    checked={tripState.isClosed}
                    style={{
                      width: '20px',
                      height: '20px',
                      accentColor: 'red'
                    }}
                  />
                  <p className="ml-2" style={{ fontSize: '14px' }}>
                    Cerrado
                  </p>
                </div>
              </div>
            </Col>
          )}
        </Row>
        <hr className="w-100" />
        <p className="font-weight-bold gray-text" style={{ fontSize: '16px' }}>
          Mi ruta es...
        </p>
        <Row className="m-0 justify-content-between">
          <Col
            md={12}
            lg={6}
            className="d-flex flex-row justify-content-start p-0"
          >
            <Col md={9} className="pl-0">
              <AddressFields
                modelName="trip[pickupAddressAttributes]"
                placeholder="Origen"
                values={values}
                errors={errors}
                touched={touched}
              />
            </Col>
            <Col md={3} className="pl-0">
              <Field name="trip[originDetour]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="number"
                    label="Desvío(km)"
                    placeholder="0km"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Col>
          <Col
            md={12}
            lg={6}
            className="d-flex flex-row justify-content-start p-0"
          >
            <Col md={9} className="pl-0">
              <AddressFields
                modelName="trip[deliveryAddressAttributes]"
                placeholder="Destino"
                values={values}
                errors={errors}
                touched={touched}
              />
            </Col>
            <Col md={3} className="pl-0">
              <Field name="trip[destinyDetour]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="number"
                    label="Desvío(km)"
                    placeholder="0km"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Col>
        </Row>
        <hr className="w-100" />
        <Row>
          <Col md={12} lg={6}>
            <p
              className=" font-weight-bold gray-text"
              style={{ fontSize: '16px' }}
            >
              Fecha retiro
            </p>
            <UrgencyButtons
              side="left"
              variantButton="primary-outline"
              rangeOptions={['between_range', 'on_date']}
              setFieldValue={setFieldValue}
              modelValues={values.trip}
              modelName="trip"
              setUrgencyBtnSelected={setUrgencyBtnSelected}
              selectedType={type => {
                if (isType(type) === 'left') setUrgencyTypeLeft(type);
              }}
            />
            {pickupOptionError &&
              !urgencyBtnSelected.left &&
              (pickupStartDateTouched || pickupEndDateTouched) && (
                <small className="text-danger mb-n1">{pickupOptionError}</small>
              )}
            {renderUrgencyInputLeft()}
          </Col>
          <Col md={12} lg={6}>
            <p
              className=" font-weight-bold gray-text"
              style={{ fontSize: '16px' }}
            >
              Fecha entrega
            </p>
            <UrgencyButtons
              side="right"
              variantButton="primary-outline"
              rangeOptions={['between_range', 'on_date']}
              setFieldValue={setFieldValue}
              modelValues={values.trip}
              modelName="trip"
              setUrgencyBtnSelected={setUrgencyBtnSelected}
              selectedType={type => {
                if (isType(type) === 'right') setUrgencyTypeRight(type);
              }}
            />
            {deliveryOptionError &&
              !urgencyBtnSelected.right &&
              (deliveryStartDateTouched || deliveryEndDateTouched) && (
                <small className="text-danger mb-n1">
                  {deliveryOptionError}
                </small>
              )}
            {renderUrgencyInputRight()}
          </Col>
          <hr className="w-100 mx-3" />

          <Col sm={12} md={4} className="ml-auto">
            <Button variant="primary" type="submit" className="mt-4" block>
              {trip ? 'Actualizar viaje' : 'Crear viaje'}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const setInitialValues = () => {
  return {
    trip: {
      id: '',
      startDate: '',
      endDate: '',
      pickupAddressAttributes: {
        streetName: '',
        communeId: '',
        latitude: '',
        longitude: '',
        isValidAddress: false
      },
      deliveryAddressAttributes: {
        streetName: '',
        communeId: '',
        latitude: '',
        longitude: '',
        isValidAddress: false
      },
      originDetour: 0,
      destinyDetour: 0,
      pickupOption: '',
      pickupStartDate: '',
      pickupEndDate: '',
      deliveryStartDate: '',
      deliveryEndDate: '',
      deliveryOption: ''
    }
  };
};

const validationSchema = Yup.object().shape({
  trip: Yup.object().shape({
    pickupAddressAttributes: Yup.object().shape({
      isValidAddress: Yup.boolean().oneOf(
        [true],
        'Dirección invalida. Busca y selecciona tu dirección'
      ),
      streetName: Yup.string().required(
        'Debes ingresar una dirección de retiro.'
      )
    }),
    deliveryAddressAttributes: Yup.object().shape({
      isValidAddress: Yup.boolean().oneOf(
        [true],
        'Dirección invalida. Busca y selecciona tu dirección'
      ),
      streetName: Yup.string().required(
        'Debes ingresar una dirección de entrega.'
      )
    }),
    originDetour: Yup.string().required('Debes ingresar desvío'),
    destinyDetour: Yup.string().required('Debes ingresar desvío'),
    pickupOption: Yup.string().required(
      'Debes seleccionar una opción de retiro'
    ),
    deliveryOption: Yup.string().required(
      'Debes seleccionar una opción de entrega'
    ),

    startDate: Yup.string().required('Debes seleccionar una fecha.'),
    endDate: Yup.date()
      .when(
        'startDate',
        (startDate, yup) =>
          startDate &&
          yup.min(
            moment(startDate).subtract(1, 'hour'),
            'La fecha de término del viaje, no puede ser anterior a la fecha de inicio del viaje'
          )
      )
      .required('Debes seleccionar una fecha.'),

    pickupStartDate: Yup.date()
      .when(
        'startDate',
        (startDate, yup) =>
          startDate &&
          yup.min(
            moment(startDate).subtract(1, 'hour'),
            'La fecha de inicio de retiro, no puede ser anterior a la fecha de inicio de viaje'
          )
      )
      .when(
        'endDate',
        (endDate, yup) =>
          endDate &&
          yup.max(
            moment(endDate).add(1, 'hour'),
            'La fecha de inicio de retiro, no puede ser posterior al fecha término del viaje'
          )
      )
      .required('Debes seleccionar una fecha.'),

    pickupEndDate: Yup.date()
      .when(
        'startDate',
        (startDate, yup) =>
          startDate &&
          yup.min(
            moment(startDate).subtract(1, 'hour'),
            'La fecha de término de retiro, no puede ser anterior a la fecha de inicio del viaje'
          )
      )
      .when(
        'pickupStartDate',
        (pickupStartDate, yup) =>
          pickupStartDate &&
          yup.min(
            moment(pickupStartDate).subtract(1, 'hour'),
            'La fecha de término del retiro, no puede ser anterior a la fecha de inicio del retiro'
          )
      )
      .when(
        'endDate',
        (endDate, yup) =>
          endDate &&
          yup.max(
            moment(endDate).add(1, 'hour'),
            'La fecha de término del retiro, no puede ser posterior a la fecha de término del viaje'
          )
      )
      .required('Debes seleccionar una fecha.'),

    deliveryStartDate: Yup.date()
      .when(
        'startDate',
        (startDate, yup) =>
          startDate &&
          yup.min(
            moment(startDate).subtract(1, 'hour'),
            'La fecha de inicio de entrega, no puede ser anterior a la fecha de inicio del viaje'
          )
      )
      .when(
        'endDate',
        (endDate, yup) =>
          endDate &&
          yup.max(
            moment(endDate).add(1, 'hour'),
            'La fecha de inicio de entrega, no puede ser posterior a la fecha de término del viaje'
          )
      )
      .required('Debes seleccionar una fecha.'),

    deliveryEndDate: Yup.date()
      .when(
        'startDate',
        (startDate, yup) =>
          startDate &&
          yup.min(
            startDate,
            'La fecha de término de entrega, no puede ser anterior a la de inicio del viaje'
          )
      )
      .when(
        'deliveryStartDate',
        (deliveryStartDate, yup) =>
          deliveryStartDate &&
          yup.min(
            deliveryStartDate,
            'La fecha de término de entrega, no puede ser anterior a la fecha de inicio de entrega'
          )
      )
      .when(
        'endDate',
        (endDate, yup) =>
          endDate &&
          yup.max(
            moment(endDate).add(1, 'hour'),
            'La fecha de término de entrega, no puede ser posterior al término del viaje'
          )
      )
      .required('Debes seleccionar una fecha.')
  })
});

const handleSubmit = values => {
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: () => setInitialValues(),
  validationSchema,
  handleSubmit
})(NewTripForm);
