import React from 'react';
import { Form } from 'react-bootstrap';

const QueryFilter = ({
  sidebar,
  customParams,
  setCustomParams,
  paramName,
  queryPlaceholder
}) => {
  const handleInputChange = e => {
    const { value } = e.target;
    setCustomParams({ ...customParams, [paramName]: value });
  };
  return (
    <Form.Control
      style={{
        height: '40px',
        width: window.innerWidth < 320 || sidebar ? '100%' : 270
      }}
      placeholder={queryPlaceholder}
      onChange={handleInputChange}
      value={customParams[paramName]}
    />
  );
};

export default QueryFilter;
