import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BasicCard = ({
  size,
  titleBtn,
  title,
  src,
  direction,
  text,
  variantBtn,
  subtitle,
  path,
  colorText,
  colorCard,
  colorBorder
}) => (
  <Card
    bg={colorCard}
    text={colorText}
    style={{ width: size }}
    border={colorBorder}
  >
    {src ? (
      <Card.Img variant={direction} src={src} />
    ) : (
      <Card.Img variant={direction} />
    )}
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      {subtitle ? (
        <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
      ) : (
        false
      )}
      <Card.Text>{text}</Card.Text>
      {titleBtn ? (
        <Button as={Link} variant={variantBtn} to={path}>
          {titleBtn}
        </Button>
      ) : (
        false
      )}
    </Card.Body>
  </Card>
);

BasicCard.propTypes = {
  size: PropTypes.string,
  titleBtn: PropTypes.string,
  title: PropTypes.string.isRequired,
  src: PropTypes.string,
  direction: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  variantBtn: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  path: PropTypes.string,
  colorText: PropTypes.string,
  colorCard: PropTypes.string,
  colorBorder: PropTypes.string
};

BasicCard.defaultProps = {
  size: '18rem',
  direction: 'top',
  variantBtn: 'info',
  path: null,
  colorText: 'black',
  colorCard: 'light',
  colorBorder: null,
  subtitle: null,
  titleBtn: null,
  src: null
};

export default BasicCard;
