import * as Yup from 'yup';

const quotationSchema = {
  driverCommissionAmount: Yup.number()
    .required('Debes ingresar el valor a cobrar.')
    .typeError('Debes ingresar solo números')
    .min(2990, 'El valor debe ser mayor o igual a 2990'),
  vehicleId: Yup.string().required(
    'Debes elegir al menos uno de tus vehículos'
  ),
  pickupAt: Yup.string().required('Debes ingresar el fecha de retiro.'),
  deliveryAt: Yup.string().required('Debes ingresar la fecha de entrega.')
};

export default quotationSchema;
