import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactTooltipComponent } from '../Tooltips';
import IcoMoon from '../Icon';

const ButtonComponent = ({
  children,
  icon,
  iconColor,
  iconSize,
  items,
  margin,
  onClickCheckBox,
  to,
  tooltipText,
  typeButton,
  variant,
  ...props
}) => {
  const tooltipComponent = (tooltipGroup = '') => (
    <ReactTooltipComponent tooltipText={tooltipGroup || tooltipText} />
  );

  const iconComponent = (iconGroup = '') => {
    return (
      <IcoMoon
        icon={iconGroup || icon}
        color={iconColor}
        size={iconSize}
        style={{ marginBottom: '1.5px' }}
      />
    );
  };

  const linkButton = () => {
    return (
      <>
        <Button
          {...props}
          to={to}
          as={Link}
          variant={variant}
          data-tip
          data-for={`registerTip-custom-btn-${tooltipText}`}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {icon && iconComponent()}
          {children}
        </Button>
        {tooltipText && tooltipComponent()}
      </>
    );
  };

  const defaultButton = () => {
    return (
      <>
        <Button
          {...props}
          variant={variant}
          data-tip
          data-for={`registerTip-custom-btn-${tooltipText}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {icon && iconComponent()}
          {children}
        </Button>
        {tooltipText && tooltipComponent()}
      </>
    );
  };

  const buttonGroup = () => {
    return (
      <>
        {items && (
          <ButtonGroup>
            {items.map(item => (
              <div key={item.key}>
                <Button
                  {...props}
                  variant={item.variant}
                  size={item.size}
                  data-tip
                  data-for={`registerTip-custom-btn-${item.tooltip}`}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {item.icon && iconComponent(item.icon)}
                  {item.title}
                </Button>
                {item.tooltip && tooltipComponent(item.tooltip)}
              </div>
            ))}
          </ButtonGroup>
        )}
      </>
    );
  };

  const iconButton = () => {
    return (
      <>
        {icon && (
          <>
            <Button
              {...props}
              variant={variant}
              to={to}
              data-tip
              data-for={`registerTip-custom-btn-${tooltipText}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px',
                margin
              }}
            >
              {icon && iconComponent()}
            </Button>
            {tooltipText && tooltipComponent()}
          </>
        )}
      </>
    );
  };

  const switchButton = () => {
    return (
      <>
        <Form.Group
          data-tip
          data-for={`registerTip-custom-btn-${tooltipText}`}
          style={{ display: 'inline-flex' }}
        >
          <Form.Check {...props} id="custom-switch" type="switch" />
        </Form.Group>
        {tooltipText && tooltipComponent()}
      </>
    );
  };

  const checkButton = () => {
    return (
      <>
        <Form.Group
          data-tip
          data-for={`registerTip-custom-btn-${tooltipText}`}
          style={{ display: 'inline-flex' }}
          onClick={onClickCheckBox}
        >
          <Form.Check {...props} type="checkbox" />
        </Form.Group>
        {tooltipText && tooltipComponent()}
      </>
    );
  };

  const renderButton = () => {
    switch (typeButton.toLowerCase()) {
      case 'link':
        return linkButton();
      case 'button':
        return defaultButton();
      case 'buttongroup':
        return buttonGroup();
      case 'icon':
        return iconButton();
      case 'switch':
        return switchButton();
      case 'checkbox':
        return checkButton();
      default:
        return defaultButton();
    }
  };
  return renderButton();
};

ButtonComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.string,
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      size: PropTypes.string
    })
  ),
  margin: PropTypes.string,
  onClickCheckBox: PropTypes.func,
  to: PropTypes.string,
  tooltipText: PropTypes.string,
  typeButton: PropTypes.string,
  variant: PropTypes.string
};

ButtonComponent.defaultProps = {
  icon: '',
  iconColor: '',
  iconSize: '20px',
  margin: '2px',
  onClickCheckBox: () => null,
  to: '',
  typeButton: 'Button',
  variant: 'primary'
};

export default ButtonComponent;
