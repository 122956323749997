import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { showSolicitudeRequest } from '../../../requests/solicitudes';
import { showQuotationRequest } from '../../../requests/quotations';
import {
  ComponentDataTable,
  DefaultHeader,
  SpinnerLoader
} from '../../../components';
import basicSolicitude from '../../../components/Solicitude/basicSolicitude';
import ItemsAndRoute from '../../../components/Client/ClientShow/ItemsAndRoute';
import QuestionAndAnswers from '../../../components/Client/ClientShow/QuestionAndAnswers';
import { Columns } from '../../../components/Quotation/Columns';
import { connect, useDispatch } from 'react-redux';
const DriverSolicitudeOfferShow = props => {
  const { match, user } = props;

  const [solicitude, setSolicitude] = useState(basicSolicitude);
  const [isFetching, setIsFetching] = useState(false);
  const [driverQuotation, setDriverQuotation] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = match.params;
  const modelUrl = '/driver/quotations';

  const fetchSolicitude = () => {
    setIsFetching(true);
    showSolicitudeRequest(id, {
      dispatch,
      params: {
        for_driver: true
      },
      successCallback: response => {
        setSolicitude(camelCaseRecursive(response.data));
        setIsFetching(false);
      }
    });
  };

  const fetchQuotation = quotationId => {
    setIsFetching(true);
    showQuotationRequest(quotationId, {
      dispatch,
      params: {
        for_driver: true
      },
      successCallback: response => {
        setDriverQuotation(camelCaseRecursive([response.data]));
        setIsFetching(false);
      }
    });
  };

  useEffect(fetchSolicitude, []);

  const {
    endDate,
    startDate,
    description,
    deliveryAddressAttributes,
    pickupAddressAttributes,
    itemsAttributes,
    hasMyOffer,
    amIBestQuotation,
    parsedLowestOfferAmount,
    parsedHighestOfferAmount,
    quotationCount,
    hasQuotation,
    currentDriverQuotation,
    pickupOption,
    deliveryOption
  } = solicitude;
  const tripId = driverQuotation[0]?.tripId;

  useEffect(() => {
    if (hasMyOffer) {
      fetchQuotation(currentDriverQuotation[0]?.id);
    }
  }, [currentDriverQuotation]);

  const setBreadCrumb = () => {
    switch (location.state || location.hash.split('#')[1]) {
      case 'fromDashboard':
        return [
          {
            key: 1,
            name: 'Inicio',
            href: '/driver/home'
          },
          { key: 2, name: 'Detalle solicitud', href: '/driver/home' }
        ];
      default:
        return [
          { key: 1, name: 'Mis ofertas', href: '/driver/quotations' },
          { key: 2, name: 'Detalle ofertas' }
        ];
    }
  };

  if (isFetching || user.id == 0) {
    return <SpinnerLoader animation="border" variant="primary" />;
  }

  return (
    <>
      <DefaultHeader breadcrumb={setBreadCrumb()} />
      {(!user.client_attributes ||
        user.client_attributes?.id != solicitude.client.id) && (
        <div className="driver-solicitud-offer">
          <ItemsAndRoute
            roleProfile="driver"
            itemsAttributes={itemsAttributes}
            description={description}
            pickupStartDate={startDate}
            deliveryEndDate={endDate}
            pickupOption={pickupOption}
            deliveryOption={deliveryOption}
            deliveryAddressAttributes={deliveryAddressAttributes}
            pickupAddressAttributes={pickupAddressAttributes}
            solicitudeId={id}
            hasMyOffer={hasMyOffer}
            amIBestQuotation={amIBestQuotation}
            parsedLowestOfferAmount={parsedLowestOfferAmount}
            parsedHighestOfferAmount={parsedHighestOfferAmount}
            quotationCount={quotationCount}
            hasQuotation={hasQuotation}
            locationUrl={location.state || location.hash.split('#')[1]}
            tripId={tripId}
          />
          <hr className="my-4" />
          {driverQuotation.length > 0 && (
            <>
              <div className="data-table border-rounded p-0 my-4 quotation-table bottom-animation">
                <ComponentDataTable
                  subHeader={false}
                  pagination={false}
                  data={driverQuotation}
                  onRequest={isFetching}
                  columns={Columns({ modelUrl, solicitude, driver: true })}
                />
              </div>
              <hr className="my-4" />
            </>
          )}
          <QuestionAndAnswers solicitudeId={id} />
        </div>
      )}
    </>
  );
};
const mapStateToProps = state => {
  const { user } = state.auth;
  return {
    user
  };
};

export default connect(mapStateToProps)(DriverSolicitudeOfferShow);
