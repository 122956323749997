import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const ReactTooltipComponent = ({ tooltipText, ...props }) => {
  return (
    <ReactTooltip {...props} id={`registerTip-custom-btn-${tooltipText}`}>
      {tooltipText}
    </ReactTooltip>
  );
};

ReactTooltipComponent.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  place: PropTypes.string,
  type: PropTypes.string
};

ReactTooltipComponent.defaultProps = {
  delayHide: 0,
  delayShow: 0,
  place: 'top',
  type: 'dark'
};

export default ReactTooltipComponent;
